import { useState, useEffect } from "react";

function useTimer(targetDate) {
  const [timeTarget, setTimetarget] = useState(targetDate);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    let _time = null
    if(timeTarget) { 
      localStorage.setItem("tm", timeTarget);
      _time = timeTarget
    } else {
      _time = localStorage.getItem("tm");
      if(!_time) return
    }

    const timerId = setInterval(() => {
      if (getTimeLeft(_time) <= 0) {
        localStorage.removeItem("tm");
      }
      setTimeLeft(getTimeLeft(_time));
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeTarget]);

  function getTimeLeft(time) {
    if (time) {
      const targetDate = new Date(parseInt(time, 10));
      const currentDate = new Date();
      const difference = targetDate - currentDate;

      const seconds = Math.floor((difference / 1000) % 60);

      return seconds;
    } else {
      return 0;
    }
  }

  return [timeLeft, setTimetarget];
}

export default useTimer;
